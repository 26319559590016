/**
 * Cvent Carina components library with custom Wedding Spot overrides. Read more about themeing
 * and tokens:
 *
 * https://carina-dev.core.cvent.org/developers/themes
 * https://carina-dev.core.cvent.org/visualstyle/tokens
 * https://carina-dev.core.cvent.org/designers/palettebuilder
 *
 */
import { createColorPalette, createColorStack, defaultFormula, finalizeColorPalette } from '@cvent/carina/utils/themeBuilder';
import { generateAllBackgroundRamps } from '@cvent/carina/utils/tokens/configurations';

/**
 * Some custom modifications for the default color palette to bring it more inline with
 * Wedding Spot brand colors
 */
const wsColorPalette = createColorPalette(
    createColorStack('#881155', 'brand', true),
    createColorStack('#881155', 'interactive', true), // TODO: make sure this is cool w/ nick 993566
    createColorStack('#006AE1', 'tertiary', true),
    createColorStack('#881155', 'accent', true),
);

/**
 * Color palette with all the different contrast levels defined, the object shape is something like:
 *
 * {
 *     default: {
 *         ...
 *         brand: {10: "rgba(...)", ...}
 *     },
 *     dark: {
 *         ...
 *         brand: {10: "rgba(...)", ...}
 *     }
 * }
 *
 * We can use this to reference colors by category + contrast level (e.g brand-70 = cpFin.default.70)
 */
const cpFin = finalizeColorPalette(wsColorPalette);
const defaultCp = cpFin.default;

/**
 * Tokens based on default color palette
 */
const tokens = generateAllBackgroundRamps(defaultFormula(cpFin));

/**
 * Overrides specific tokens to add more customization to component styles
 */
export default {
    ...tokens,
    backgroundColor: {
        ...tokens.backgroundColor,
        interactive: {
            ...tokens.backgroundColor.interactive,
            fill: {
                ...tokens.backgroundColor.interactive.fill,
                base: defaultCp.brand[70],
                hover: defaultCp.brand[100],
                active: defaultCp.brand[150],
                focus: defaultCp.brand[45],
            },
        },
        tertiary: {
            ...tokens.backgroundColor.tertiary,
            fill: {
                ...tokens.backgroundColor.tertiary.fill,
                base: defaultCp.brand[70],
                surface: '#69717A',
            },
        },
        accent: {
            ...tokens.backgroundColor.accent,
            active: defaultCp.brand[70],
            fill: {
                ...tokens.backgroundColor.accent.fill,
                surface: defaultCp.brand[70],
            },
        },
        brand: {
            ...tokens.backgroundColor.brand,
            fill: {
                ...tokens.backgroundColor.brand.fill,
                focus: '#69717A',
            },
        },
        click: {
            ...tokens.backgroundColor.click,
            base: defaultCp.brand[70],
            hover: defaultCp.brand[100],
            active: defaultCp.brand[150],
            focus: defaultCp.brand[100],
        },
        clickHard: {
            ...tokens.backgroundColor.clickHard,
            hover: defaultCp.brand[100],
            active: defaultCp.brand[150],
            focus: defaultCp.brand[100],
        }
    },
    borderColor: {
        ...tokens.borderColor,
        interactive: {
            ...tokens.borderColor.interactive,
            base: defaultCp.brand[70],
            hover: defaultCp.brand[100],
            active: defaultCp.brand[150],
            focus: defaultCp.brand[100],
        },
        brand: {
            ...tokens.borderColor.brand,
            base: defaultCp.brand[70],
            hover: defaultCp.brand[100],
            active: defaultCp.brand[150],
            focus: defaultCp.brand[100],
        },
    },
    font: {
        ...tokens.font,
        color:{
            ...tokens.font.color,
            brand: {
                ...tokens.font.brand,
                base: defaultCp.brand[70],
                hover: defaultCp.brand[100],
                active: defaultCp.brand[150],
                focus: defaultCp.brand[100],
            }
        }
    }
};
