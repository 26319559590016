export interface HoganConfig {
    cdn: string;
    amplitude: {
        api_key: string;
    };
    google_analytics: {
        id: string;
    };
    google_analytics4: {
        id: string;
    };
    google_maps: {
        api_key: string;
    };
    google_places: {
        api_key: string;
    };
    meta_robots: string;
    csn: {
        search_service: {
            base_url: string;
            env: string;
        };
        venue_profile_service: {
            base_url: string;
            env: string;
        };
        rfp_service: {
            base_url: string;
            env: string;
        };
        gdpr_service: {
            base_url: string;
        };
    };
    sentry: {
        dsn: string;
    };
    ws_api_client: {
        base_url: string;
    };
    datadog: {
        applicationId: string;
        clientToken: string;
        sessionSampleRate: number;
        sessionReplaySampleRate: number;
    };
    clarity : {
        id: string;
    }
    gtm : {
        id: string;
    }
    pendo: {
        api_key: string;
    };
    srfp: {
        base_url: string;
        access_key: string;
    };
    ws_captcha: {
        site_key: string;
    };
    cvent_salesforce: {
        url: string;
    };
    social_tables: {
        csn_integration: {
            base_url: string;
        }
    }
}

export const IS_PROD_ENV = process.env.NODE_ENV === 'production';
export type IS_PROD_ENV = typeof IS_PROD_ENV;

export const IS_DEV_ENV = process.env.NODE_ENV === 'development';
export type IS_DEV_ENV = typeof IS_DEV_ENV;

export const IS_STAGING_ENV = process.env.NEXT_PUBLIC_IS_STAGING === '1';
export type IS_STAGING_ENV = typeof IS_STAGING_ENV;

export const IS_ALPHA_ENV = process.env.NEXT_PUBLIC_IS_ALPHA === '1';
export type IS_ALPHA_ENV = typeof IS_ALPHA_ENV;

export const GMAPS_API_KEY = IS_DEV_ENV ? 'AIzaSyD0SItm12C2f8xFzcxpo_bg04AQMqUxsWA' : 'AIzaSyAgP8Uj4xoGv5dYwAnGP2TAbU8lONOkXMs';

export const SERVER_ADDRESS = IS_DEV_ENV ? 'http://localhost:8000' : '';
export type SERVER_ADDRESS = typeof SERVER_ADDRESS;

//@ts-ignore
export const DD_ENV = (typeof ddEnv !== undefined) ? (ddEnv as string) : 'local';
export type DD_ENV = typeof DD_ENV;

export const DD_SERVICE = process.env.DD_SERVICE ? process.env.DD_SERVICE : 'ws-next';
export type DD_SERVICE = typeof DD_SERVICE;

//@ts-ignore
export const DD_VERSION = (typeof ddVersion !== undefined) ? (ddVersion as string) : 'undefined';
export type DD_VERSION = typeof DD_VERSION;

export const META_ROBOTS = IS_PROD_ENV && !IS_STAGING_ENV && !IS_ALPHA_ENV ? 'index, follow' : 'noindex, nofollow';

export const ALB_META_ROBOTS = 'noindex, nofollow';

const readConfig = () => {
    return typeof process.env.HOGAN_CONFIG === 'string' ? (JSON.parse(process.env.HOGAN_CONFIG) as HoganConfig) : process.env.HOGAN_CONFIG;
};

export const HOGAN_CONFIG: HoganConfig = readConfig();

export const PINO_CONFIG = { 
    formatters: { 
        level: (label: any) => { 
            return { 
                level: label 
            }
        }
    },
    level: 'warn',
};