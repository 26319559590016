import { IS_DEV_ENV, IS_STAGING_ENV, IS_ALPHA_ENV } from '../configs/appconfig';

export const WEDDING_VENUES = '/wedding-venues/';

export const TYPEAHEAD_CDN_URL =
    IS_STAGING_ENV || IS_DEV_ENV
        ? 'https://cdn-sg50.wedding-spot.com/typeahead/typeahead_1.json'
        : IS_ALPHA_ENV
        ? 'https://cdn-ap50.wedding-spot.com/typeahead/typeahead_1.json'
        : 'https://cdn.wedding-spot.com/typeahead/typeahead_1.json';

export const getLoacationFullURL = (slug: string) => WEDDING_VENUES + slug.toLowerCase();

export const getVenueURL = (venueId: number | string, slug?: string) => '/venue/' + venueId + '/' + (slug ? slug.toLowerCase() + '/' : '');

export const getStyleURL = (slug: string) => WEDDING_VENUES + slug.toLowerCase() + '/';

export const getStyleSrpUrl = (slug: string) => `/wedding-venues/${slug.toLowerCase()}-weddings/`;
