import * as React from 'react';
import Link from 'next/link';

const LinkNext = (
    props: React.PropsWithChildren<{
        url: string;
        className?: string;
        onClick?: () => void;
    }>
) => {
    return (
        <Link href={props.url} prefetch={false}>
            <a className={props.className} onClick={props.onClick}>
                {props.children}
            </a>
        </Link>
    );
};

export default LinkNext;
