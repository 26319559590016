import { HOGAN_CONFIG, DD_ENV, DD_SERVICE, DD_VERSION, PINO_CONFIG} from '../configs/appconfig';
import { LoggerFactory } from '@cvent/logging/LoggerFactory';
import pino from 'pino';

class AppLogger {
    loggerInitialized: boolean = false;
    loggerInstance: any = pino(PINO_CONFIG);

    checkVariables() {
        return typeof window !== 'undefined';
    }

    createLogger() {
        if (this.checkVariables()) {
            if(!this.loggerInitialized){
                this.initLogger();
            }
            this.loggerInstance = LoggerFactory.create('App');
        } else {
            this.loggerInstance = pino(PINO_CONFIG);
        }
        return this.loggerInstance;
    }

    initLogger() {
        const variableDefined = this.checkVariables();
        if (this.loggerInitialized || !variableDefined) {
            return this.loggerInitialized;
        }
        if (variableDefined) {
            LoggerFactory.initConfig({
                datadogOptions: {
                    clientToken: HOGAN_CONFIG.datadog.clientToken,
                    service: DD_SERVICE || 'ws-next',
                    env: DD_ENV,
                    version: DD_VERSION,
                    forwardConsoleLogs: ['warn','error'],
                    forwardErrorsToLogs: true,
                },
                loggerOptions: {
                    level: 'warn'
                }
            });
            this.loggerInitialized = true;
        }
        return this.loggerInitialized;
    }
}

export default new AppLogger();