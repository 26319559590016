import {
    CartType,
    FeaturedFlagsContextProvider,
    Footer,
    HeaderContextProvider,
    inquiryModalReducers,
    ShoppingCartContextProvider,
    ToastProvider,
    WSAppContextProvider,
    getUserProps,
} from '@weddingspot/ws-core-components';
import * as React from 'react';
import dynamic from 'next/dynamic';
import App from 'next/app';
import isbot from 'isbot';
import { Provider as ReduxStoreProvider } from 'react-redux';
import { combineReducers, createStore } from 'redux';
import { useRouter } from 'next/router';
import { ThemeProvider } from '@cvent/carina-theme-provider';
import { ThemeProvider as V1ThemeProvider } from '@cvent/carina/components/ThemeProvider';
import wsCarinaTheme from '../utils/carina-theme';

import 'rc-tooltip/assets/bootstrap.css';
import '../styles/globals.scss';
import LinkNext from './../components/ui/link';

import { AnalyticsEvent, logEvent } from '@weddingspot/ws-analytics';
import * as URLS from '../utils/urls';

const TYPEAHEAD_DATA_TTL = 1000 * 60 * 60 * 2;

// import AppHeader from '../components/header/AppHeader';
const AppHeader = dynamic(() => import('../components/header/AppHeader'));
import { ReviewFormPageHeader } from '@weddingspot/ws-core-components';
import {META_ROBOTS as WS_META_ROBOTS, ALB_META_ROBOTS, HOGAN_CONFIG, DD_ENV, DD_SERVICE, DD_VERSION} from 'configs/appconfig';

import { datadogRum } from '@datadog/browser-rum';

import {SessionExtend, handleTimeoutToDisplaySessionExtendWidget, extendSessionAtInterval} from '@weddingspot/ws-core-components';
import { Appointments } from '@weddingspot/ws-api-client';
import Head from 'next/head';
import AppLogger from '../utils/logging';

function initializeDatadogRum() {
    const tracingOriginsRegexp = /^(http(s)?:\/\/)?(sg50|ap50|ts50)?\.wedding-spot\.com(.*)$|^(http(s)?:\/\/)?(www.)?\wedding-spot\.com(.*)|^(http(s)?:\/\/)?ws-(render|next|core)-(ap50-|sg50-|ts50-)?ws(dv|pr)\.(us-east-1|us-west-2)\.lb\.(cvent\.com)(.*)$/gm
    const excludedActivityUrlsRegex = /(.*)(amplitude)(.*)|(.*)(analytics.google)(.*)|(.*)(google-analytics)(.*)|(.*)(clarity.ms)(.*)|(.*)(pinterest)(.*)|(.*)(doubleclick)(.*)|(.*)(salesloft)(.*)/gm
    datadogRum.init({
        applicationId: HOGAN_CONFIG.datadog.applicationId,
        clientToken: HOGAN_CONFIG.datadog.clientToken,
        site: 'datadoghq.com',
        service: DD_SERVICE || 'ws-next',
        env: DD_ENV,
        version: DD_VERSION, // deployed version of the application
        sessionSampleRate: Number(HOGAN_CONFIG.datadog.sessionSampleRate),
        sessionReplaySampleRate: Number(HOGAN_CONFIG.datadog.sessionReplaySampleRate),
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel:'mask-user-input',
        allowedTracingUrls: [tracingOriginsRegexp],
        trackSessionAcrossSubdomains: true,
        useSecureSessionCookie: true,
        useCrossSiteSessionCookie: true, // This is deprecated in v5 in favor of usePartitionedCrossSiteSessionCookie,
        // but there is a potential breaking issue with use of that param. Please refer:
        // https://github.com/cvent-internal/development-framework/pull/3397/files#diff-b828bf5b40be25b10e9d4dc4f03da7271d4fc404f8660c8390acd4a7bafdd309R17
        // and https://cvent.slack.com/archives/C01EWNQ7K5E/p1718295869782689?thread_ts=1718122761.529549&cid=C01EWNQ7K5E
        traceSampleRate: 25,
        traceContextInjection: 'sampled',
        excludedActivityUrls: [excludedActivityUrlsRegex],
    })
}

function setDDGlobalContext(){
    const env = DD_ENV || 'local';
    const version = DD_VERSION || 'undefined';
    getUserProps(env,version).then((userProps: any) => {
        Object.entries(userProps).forEach(([key, value]) => {
            datadogRum.setGlobalContextProperty(key, value)
        })
    });
}

const store = createStore(
    combineReducers({
        ...inquiryModalReducers,
    })
);

function MyApp({ Component, pageProps, isBot, profileVersion, metaRobots }: any) {
    const router = useRouter();
    const reviewFormPagePath = '^(\/review/.*/?)$'
    const defaultHeaderContextProps = {
        headerItemClicked: (item: string) => logEvent(AnalyticsEvent.EVTNM_CLICKED_HEADER_ITEM, { Item: item }),
        headerLocationItemClicked: (source: string, value: string) =>
            logEvent(AnalyticsEvent.EVTNM_CLICKED_LOCATIONS_ITEM, { Source: source, Value: value }),
        onSearchResultClicked: (link: string, inputValue: string) =>
            logEvent(AnalyticsEvent.EVTNM_CLICKED_SEARCH, { Link: link }, undefined, inputValue),
        dataUrl: URLS.TYPEAHEAD_CDN_URL,
        dataTTL: TYPEAHEAD_DATA_TTL,
        profileVersion: profileVersion,
        options: {
            maxResultsPerSection: 5,
            locationUrlFormatter: URLS.getLoacationFullURL,
            venueUrlFormatter: URLS.getVenueURL,
            styleUrlFormatter: URLS.getStyleSrpUrl,
        },
    };

    const onSuccessfulOutreach = (request: Appointments.MassInquiryRequestParams, sourcePage?: string) => {
        logEvent(AnalyticsEvent.EVTNM_SUCCESSFUL_COUPLE_OUTREACH, {
            source: sourcePage ? sourcePage : 'venues SRP shopping cart',
            VenueCount: request.vendor_ids.length,
            ...request.venues_analytics,
        });
    };

    const [isOpen, setIsOpen] = React.useState(false)
    React.useEffect(() => {
        initializeDatadogRum();
        setDDGlobalContext();
        AppLogger.createLogger();
        extendSessionAtInterval();
        handleTimeoutToDisplaySessionExtendWidget(setIsOpen);
    }, []);


    return (
        <ReduxStoreProvider store={store}>
            <V1ThemeProvider theme={wsCarinaTheme}>
            <ThemeProvider theme={wsCarinaTheme}>
                <WSAppContextProvider rootNodeId='root' isNext={true} linkComponent={LinkNext} {...({} as any)}>
                    <ToastProvider>
                        <FeaturedFlagsContextProvider isBot={isBot}>
                            <ShoppingCartContextProvider cartType={CartType.VENUES} itemsMax={10} onSuccessfulOutreach={onSuccessfulOutreach}>
                                <div id='root' role='main'>
                                    <Head>
                                        <title>Wedding Venues &amp; Planning Tools | Wedding Spot</title>
                                        <meta name="robots" content={metaRobots} />
                                        <meta name="theme-color" content='#881155' />
                                        <meta name="application-name" content='Wedding Spot, Search, price &amp; compare wedding venues' />
                                    </Head>
                                    <HeaderContextProvider {...defaultHeaderContextProps}>
                                        <SessionExtend isOpen={isOpen} setIsOpen={setIsOpen} />
                                        {router.pathname.match(reviewFormPagePath) ? <ReviewFormPageHeader /> : <AppHeader />}
                                        <Component {...pageProps} />
                                        {router.pathname.match(reviewFormPagePath) ? null : <Footer />}
                                    </HeaderContextProvider>
                                </div>
                            </ShoppingCartContextProvider>
                        </FeaturedFlagsContextProvider>
                    </ToastProvider>
                </WSAppContextProvider>
            </ThemeProvider>
            </V1ThemeProvider>
        </ReduxStoreProvider>
    );
}

MyApp.getInitialProps = async (appContext) => {
    const appProps = await App.getInitialProps(appContext);
    let META_ROBOTS='';

    if (!appContext.ctx.req.headers['x-app-domain-hit']) {  
        META_ROBOTS = ALB_META_ROBOTS;
    }else{
        META_ROBOTS = WS_META_ROBOTS;
    }

    return {
        isBot: isbot(appContext.ctx.req.headers['user-agent']),
        profileVersion: appContext.ctx.req.headers['x-profile-version'],
        metaRobots: META_ROBOTS,
        ...appProps,
    };
};

export default MyApp;
